<script lang="ts">
import { ComponentPublicInstance, defineComponent, ref } from 'vue'

import AsyncIcon from './atoms/AsyncIcon.vue'

export type PreloaderPublicInstance = ComponentPublicInstance & {
  visible: boolean
}

export default defineComponent({
  name: 'ThePreloader',
  components: {
    AsyncIcon
  },
  setup (props, { expose }) {
    const publicInstance = {
      visible: ref(true)
    }
    expose(publicInstance)
    return publicInstance
  }
})
</script>

<template>
  <transition mode="out-in">
    <div v-if="visible" id="app-loading-container">
      <div id="app-loading">
        <AsyncIcon name="logo-v-min" class="icon" />
      </div>
    </div>
  </transition>
</template>

<style lang="scss">
#app-loading {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $c-beige-reduced;
  color: $c-brown;

  .icon {
    margin-bottom: rem(20px);
    font-size: rem(68px);
  }

  .description {
    max-width: rem(200px);
    font-size: rem(16px);
    text-align: center;

    @screen lg {
      max-width: rem(291px);
      font-size: rem(24px);
    }
  }
}

</style>
