<script lang="ts">
import { defineComponent, shallowRef, watch } from 'vue'

export default defineComponent({
  name: 'AsyncIcon',
  props: {
    name: { type: String, required: true }
  },
  setup (props) {
    const icon = shallowRef(null)

    watch(() => props.name, () => {
      import(`@/assets/icons/${props.name}.svg`)
        .then(module => (icon.value = module.default))
        .catch(null)
    }, { immediate: true })

    return { icon }
  }
})
</script>

<template>
  <transition mode="out-in">
    <component
      :is="icon" v-if="icon"
      :key="icon"
      class="icon inline-block"
      aria-hidden="true"
      focusable="false"
      alt=""
    />
  </transition>
</template>

<style lang="scss" scoped>
.icon:not([class*="w-"]) {
  width: 1em;
}
</style>
